.infoBoxes {
    display:flex;
    align-content: center;
    justify-content: center;
    height:fit-content;
    flex:1;
    padding-top:10em;
    padding-right:3em;
    padding-left:3em;
    position: relative;
}

.boxContainer {
    max-width:500px;
    margin-right:5em;
    width:100%;
    border:1px solid #D259C7;
    border-radius:2em;
    /* height:fit-content; */
    display:flex;
    flex-direction: column;
    padding:2em 0;
    position: relative;
    background-color:rgba(255,255,255, 0.47);
    padding-top:12%;
}
.mobile .infoBoxes {
    padding: 0 2%;

}
.mobile .boxContainer {
    margin:0;
    margin-top:25%;
    /* height:60vh; */
    padding-top:9em;
}
.mobile .infoBoxes > .boxContainer:nth-last-child(1) {
    margin-bottom:3%;
}
.tablet .boxContainer {
    padding-top:120px;
}
.boxContainer > img{
    border:1px solid #D259C7;
    border-radius: 50%;
    position: absolute;
}
.desktop .boxContainer > img {
    top:-6em;
    left:20%;
    width:60%;
}
.tablet .boxContainer > img {
    top:-4em;
    left:35%;
    width:30%;
}
.mobile .boxContainer > img {
    top:-4.5em;
    left:35%;
    width:30%;
}

.boxContainer > h3 {
    width:80%;
    margin:0 auto;
    text-align: center;

}
    .boxContainer > h3 > .headerNr {

    }
    .boxContainer > h3 > .headerHeader {

    }
.boxContainer > p {
    width:80%;
    margin:0 auto;
    margin-top:15px;
}

.tablet .infoBoxes {
    flex-direction: column;
    padding-top:10%;

}
.mobile .infoBoxes {
    flex-direction: column;
}

.tablet .infoBoxes > .boxContainer {
    margin:0 auto;
    margin-bottom:10%;
}
.tablet .infoBoxes > .boxContainer:nth-last-child(1) {
    margin-bottom:3%;
}