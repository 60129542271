.button {
   width: 30vw;
   max-width: 200px;
   min-width: 100px;
   height: 40px;
   font-size: min(5vw, 20px);

   margin: auto;
   border: 20px;
   background-color: #d259c7;
   color: white;
   border-radius: 2em;
   letter-spacing: 2.5px;
   font-family: var(--menu-font-family);
   outline: none;
   text-transform: uppercase;
   transition: background-color 0.2s ease-in-out;
   -webkit-transition: background-color 0.2s ease-in-out;
   -moz-transition: background-color 0.2s ease-in-out;
   -ms-transition: background-color 0.2s ease-in-out;
   -o-transition: background-color 0.2s ease-in-out;
}
.button:hover {
   cursor: pointer;
   background-color: #c521b5;
}
