.footer {
   display: flex;
   background-color: #d259c7;
   color: #ffffff;
   padding: 3em;
   height: fit-content;
}
.footer h3 {
   font-family: var(--menu-font-family);
}
.footer > section {
   flex: 1;
   display: flex;
   flex-direction: column;
   /* align-items: center; */
   padding: 2em;
   /* border-left:1px solid #FFF; */
}
.footer > .part1 > img {
   margin-bottom: 2em;
}
.footer > section:nth-child(2) {
   border-right: 1px solid #ffffff;
   border-left: 1px solid #ffffff;
}
.footer a {
   color: #ffffff;
}
.footer h3 {
   text-align: center;
}
.footer img {
   width: 80%;
}
.footer > .part2 > div:not(:nth-child(1)) {
   margin-top: 2em;
}
.footer > .part2 > div > p {
   margin: 0;
}
.footer > .part2 > .googleMapContainer {
   font-family: var(--menu-font-family);
   letter-spacing: 2px;
}
.footer > .part3 > .socialLinks {
   display: flex;
   flex-direction: row;
   height: 100%;
   padding: 2em;
   justify-content: center;
}
.footer > .part3 > .socialLinks > a:not(:nth-child(1)) {
   margin-left: 6%;
}

.mobile .footer > .part3 > .socialLinks > a:not(:nth-child(1)) {
   /* margin-left:0; */
   margin: auto;
   margin-top: 1em;
}
.mobile .footer > .part3 > .socialLinks {
   flex-direction: column;
}

.footer > .part3 > .socialLinks > a {
   display: block;
   width: 50px;
   margin: auto;
   /* width:30%; */
   /* min-width:25px; */
}
.footer > .part3 > .socialLinks > a > img {
   width: 100%;
}
.mobile .footer {
   flex-direction: column;
   text-align: center;
}

.desktop .footer > .part2 > h3 {
   margin-bottom: 6px;
}
.tablet .footer > .part2 > h3 {
   margin-bottom: 6px;
}

.mobile .footer > section:nth-child(2) {
   border: none;
}

.mobile .footer > .part3 > h3 {
   margin-bottom: 0.7em;
}
