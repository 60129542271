.fiftyBlockContainer {
   width: 100vw;
   display: flex;
   flex-wrap: wrap;
   font-size: 1em;
}
.fiftyBlockContainer .article {
   position: relative;
   height: calc(110vh - 100px);
   width: 100%;
   background-color: #fff;
   display: flex;
   flex-direction: column;
   justify-content: center;
}
.fiftyBlockContainer .article {
   width: 50%;
}
.tablet .article {
   width: 100%;
   height: fit-content;
   min-height: calc(50vh - 50px);
}
.mobile .article {
   width: 100%;
   height: fit-content;
   min-height: calc(50vh - 50px);
}

/* _______________________ Img _______________________  */
.mobile .article.img_article,
.tablet .article.img_article {
   height: calc(50vh - 100px);
}
.fiftyBlockContainer .article.img_article img {
   object-fit: cover;
   object-position: center;
   height: 100%;
   width: 100%;
}
.article.framed {
   flex-direction: column;
   justify-content: center;
   padding: 4em;
   background-color: #d259c7;
}
.article.framed > img {
   border: 8px solid white;
}

/* _______________________ Table _______________________  */
.table_article {
   padding: 2em 4em;
   display: flex;
}

.tablet .table_article {
   padding: 4em 10em;
}
.mobile .table_article {
   padding: 5em 4em;
}

.table_article .table {
   display: flex;
   justify-content: space-between;
   text-align: left;
}
.table_article .tableBtn {
   display: flex;
   width: fit-content;
   margin: 0 auto;
   margin-top: 80px;
   margin-bottom: 40px;
}
.table_article .table .column {
   display: flex;
   flex-direction: column;
}
.table_article .table .column .col_title {
   font-weight: bold;
}
.table_article .table .column .row {
   flex: 1;
}
.table_article .welcomeHeader {
   margin-bottom: 20px;
}
/* header */
.article .welcomeHeader {
   position: relative;
   text-align: center;
   font-size: 3em;
   flex: 0;
}
.article .welcomeHeader > .headerNr {
   font-size: 0.85em;
   position: relative;
   right: 0.5em;
}
.article .welcomeHeader > .headerHeader {
   font-size: 1em;
}

/* Text */
.article.text_article {
   padding: 2em 4em;
}

.tablet .article.text_article {
   padding: 4em 10em;
}
.mobile .article.text_article {
   padding: 5em 4em;
}
.article.text_article p {
   margin-top: 15px;
}
