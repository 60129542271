.firstPage {
   position: relative;
   margin-top: 100px;
   width: 100vw;
}
.firstPage > .firstPageBackgroundImg {
   object-fit: cover;
   object-position: 70% 50%;
   height: calc(100vh - 100px);
   width: 100%;
}
.mobile .firstPage > .firstPageBackgroundImg {
   height: calc(50vh - 100px);
}

.firstPage > .firstPageContent {
   width: 100%;
   height: 100%;
   position: absolute;
   z-index: 2;
   top: 0;
   left: 0;
   padding-left: 4%;
   justify-content: center;
   display: flex;
   flex-direction: column;
}

.firstPage > .firstPageContent > .firstPageTextImg {
   width: 80%;
   position: relative;
   max-width: 450px;
   min-width: 150px;
   margin-bottom: min(10vw, 150px);
}
.mobile .firstPage > .firstPageContent > .firstPageTextImg {
   width: 80%;
   min-width: 50px;
}

.firstPage > .firstPageContent > .firstPageBookLink {
   position: relative;
   left: 6%;
   display: inline-block;
   width: fit-content;
   height: fit-content;
}
