.header {
   position: fixed;
   height: 100px;
   top: 0;
   z-index: 10;
   background: linear-gradient(103.21deg, #ffbeed 0.18%, #f8edf5 88.82%);
   left: 0;
   right: 0;
   font-family: var(--menu-font-family);
}
.header .logoContainer img {
   height: 60px;
   /* width: 20em; */
}
/* Desktop */
.header > .desktopView {
   display: flex;
   padding: 20px 10em;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   height: 100%;
}
.header > .desktopView > .logoContainer {
   margin-left: 2em;
   margin-right: 2em;
}
.header > .desktopView > li {
   flex: 1;
   text-align: center;
}

.header a {
   font-size: 1.2em;
}
.header a:hover,
.header a:active {
   color: #d272c9;
   border-bottom: 2px solid black;
}

/* Tablet + Mobile */

.header > .notDesktopView {
   display: flex;
   padding: 20px 40px;
   justify-content: space-between;
   align-items: center;
   height: 100%;
   box-sizing: border-box;
}
.header > .notDesktopView > div > .menuButton {
   width: 30px;
   cursor: pointer;
}
.header > .notDesktopView > div > .closeMenuBtn {
   font-size: 45px;
   cursor: pointer;
}
.header > .notDesktopView > div > .menuButton > div {
   border-top: 3px solid black;
   padding: 4px;
   box-sizing: border-box;
}
.header > .notDesktopView > div > .menuButton > div:nth-child(3) {
   padding: 0;
}
.header > .notDesktopView > div > ul {
   display: flex;
   visibility: hidden;
   flex-direction: column;
   align-items: center;
   background: linear-gradient(103.21deg, #ffbeed 0.18%, #f8edf5 88.82%);
   position: absolute;
   width: 100vw;
   top: 100px;
   justify-content: space-evenly;
   left: 0;
   right: 0;
   height: 0;
   transition: visibility 0.3s ease-in-out, height 0.3s ease-in-out;
   -webkit-transition: visibility 0.3s ease-in-out, height 0.3s ease-in-out;
   -moz-transition: visibility 0.3s ease-in-out, height 0.3s ease-in-out;
   -ms-transition: visibility 0.3s ease-in-out, height 0.3s ease-in-out;
   -o-transition: visibility 0.3s ease-in-out, height 0.3s ease-in-out;
}
.header > .notDesktopView > div > ul > li {
   opacity: 0;
   transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
   -webkit-transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
   -moz-transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
   -ms-transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
   -o-transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
}
.header > .notDesktopView > div > .menuOpenedtrue > li {
   opacity: 1;
}
.header > .notDesktopView > div > ul > li > a {
   font-size: 30px;
   font-weight: bold;
}
.header > .notDesktopView > div > .menuOpenedtrue {
   height: calc(100vh - 100px);
   visibility: visible;
   z-index: 10;
}

@media only screen and (max-width: 320px) {
   .header > .desktopView {
      padding: 10px 20px;
   }
   .header > .notDesktopView {
      padding: 20px 12px;
   }
}
