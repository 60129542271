.contact {
   display: flex;
   flex-direction: column;
   max-width: 1000px;
   margin: auto;
   padding: 4em 5em;
   position: relative;
   /* justify-content: center; */
}
.contact input {
   height: 50px;
}

input:focus,
textarea:focus {
   outline: none;
}
.contact textarea {
   padding-top: 10px;
}

.contact > .contactHeader {
   text-align: center;
   position: relative;
   font-size: 3em;
}
.contact > .contactHeader > .headerNr {
   position: relative;
   right: 0.5em;
   font-size: 0.9em;
}

.contact > .contactIngress {
   margin: 25px 0;
   text-align: center;
}

.contact > form {
   display: flex;
   flex-direction: column;
   border: 2px solid #d272c9;
   border-radius: 2em;
   background-color: rgba(255, 255, 255, 0.47);
   margin-bottom: 1em;
}
.contact > form > .msgTextarea {
   border: none;
   resize: none;
   height: 30vh;
   margin-bottom: 12px;
}
.contact > form > .inputContainer {
   border-bottom: 2px solid #d272c9;
}
.contact > form > .inputContainer > .formRow {
   display: flex;
   background-color: transparent;
}
.contact > form > .inputContainer > .formRow > input {
   width: 50%;
   font-family: var(--menu-font-family);
   border: none;
}
.contact > form > .inputContainer > .formRow > .mailInputContainer {
   display: flex;
   flex-direction: row;
   width: 50%;
   align-items: center;
   border: none;
   border-left: 2px solid #d272c9;
}
.contact > form > .inputContainer > .formRow > .mailInputContainer > input {
   /* flex:1; */
   width: 100%;
   border: none;
}

.tablet .contact > form > .inputContainer > .formRow > input {
   width: 100%;
}
.mobile .contact > form > .inputContainer > .formRow > input {
   width: 100%;
}

.contact .successMsg {
   display: block;
   position: relative;
   text-align: center;
   margin: auto;
   margin-top: 1em;
   font-size: 13px;
   font-family: var(--menu-font-family);
}

@media only screen and (max-width: 800px) {
   .app .contact > form > .inputContainer > .formRow {
      flex-direction: column;
   }
   .contact > form > .inputContainer > .formRow > .mailInputContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      border: none;
      border-top: 2px solid #d272c9;
   }
}
.contact input,
.contact textarea {
   background-color: transparent;
   font-family: var(--menu-font-family);
   padding-left: 20px;
   padding-right: 20px;
}
