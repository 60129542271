.welcome {
   /* padding:0 20%; */
   box-sizing: border-box;
   /* height:30vh; */
   display: flex;
   flex-direction: column;
   /* align-content: center; */
   justify-content: center;
   padding: 50px 0;
   height: 50vh;
}

.welcome > .welcomeHeader {
   text-align: center;
   position: relative;
   font-size: 3em;
}
.welcome > .welcomeHeader > span:nth-child(1) {
   position: relative;
   right: 0.5em;
   font-size: 0.85em;
}

.welcome > .welcomeIngress {
   width: 60%;
   padding-top: 20px;
   margin: 0 auto;
   text-align: center;
}

.mobile .welcome {
   height: 50vh;
}
