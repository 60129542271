.loadingScreen {
   position: fixed;
   z-index: 50;
   top: 0;
   left: 0;
   width: 100vw;
   height: 101vh;
   background-color: black;
   color: white;
   font-size: 5em;

   display: flex;
   justify-content: center;
   align-items: center;
}
.loading {
   opacity: 1;
   visibility: visible;
   transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
   -webkit-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
   -moz-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
   -ms-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
   -o-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
}
.loadingComplete {
   opacity: 0;
   visibility: hidden;
   transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
   -webkit-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
   -moz-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
   -ms-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
   -o-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
}
