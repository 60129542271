.singularImg {
   width: 80%;
   margin: auto;
}
.mobile .singularImg {
   width: 90%;
}
.singularImg img {
   object-fit: cover;
   width: 100%;
}
