* {
   box-sizing: border-box;
}
html,
body,
#root {
   margin: 0;
   padding: 0;
   --body-font-family: "Raleway", sans-serif, Arial, Helvetica;
   --header-font-family: "Modern Antiqua", sans-serif, Arial, Helvetica;
   --menu-font-family: "FuturaBT-Heavy", sans-serif, Arial, Helvetica;
   overflow-x: hidden;
   line-height: 150%;
}
html > .mobile {
   font-size: 2.5vw;
}
html > .tablet {
   font-size: 1.5vw;
}
html > .desktop {
   font-size: 1vw;
}
strong {
   font-weight: bold;
}
* {
   box-sizing: border-box;
}
@font-face {
   font-family: "FuturaBT-Heavy";
   src: url("./lib/fonts/FuturaHeavyfont.eot"); /* IE9 Compat Modes */
   src: url("./lib/fonts/FuturaHeavyfont.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("./lib/fonts/FuturaHeavyfont.otf") format("opentype"),
      /* Open Type Font */ url("./lib/fonts/FuturaHeavyfont.svg") format("svg"),
      /* Legacy iOS */ url("./lib/fonts/FuturaHeavyfont.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("./lib/fonts/FuturaHeavyfont.woff") format("woff"),
      /* Modern Browsers */ url("./lib/fonts/FuturaHeavyfont.woff2") format("woff2"); /* Modern Browsers */
   font-weight: normal;
   font-style: normal;
}
.linkDestination {
   position: absolute;
   top: -99px;
}
ul {
   margin: 0;
   padding: 0;
}
a {
   color: black;
   text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
   margin: 0;
   font-family: var(--header-font-family);
   letter-spacing: 3px;
   line-height: 150%;
}
p {
   font-family: var(--body-font-family);
   letter-spacing: 1.2px;
   line-height: 175%;
}
input {
   font-family: var(--body-font-family);
   letter-spacing: 2px;
}
li {
   list-style-type: none;
}
.headerNr {
   font-family: var(--menu-font-family);
   -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: #d272c9;
}

/* App */
.app {
   background: linear-gradient(103.21deg, #ffbeed 0.18%, #f8edf5 88.82%);
   height: fit-content;
}
